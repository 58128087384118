.actions {
  @apply flex p-1 border-accent-2 border items-center justify-center
  w-12 text-accent-7;
  transition-property: border-color, background, color, transform, box-shadow;

  transition-duration: 0.15s;
  transition-timing-function: ease;
  user-select: none;
}

.actions:hover {
  @apply border bg-accent-1 border-accent-3 text-accent-9;
  transition: border-color;
  z-index: 10;
}

.actions:focus {
  @apply outline-none;
}

.actions:disabled {
  @apply cursor-not-allowed;
}

.input {
  @apply bg-transparent px-4 w-full h-full focus:outline-none select-none pointer-events-auto;
}

.remoteBtn {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(71, 71, 71, 1);
  border-bottom: 1px solid rgba(71, 71, 71, 0.6);
  margin-top: -2px;
}

.quantityInput {
  background-color: transparent;
  font-size: 13px;
  padding: 0px 7px 0px 9px;
  text-align: center;
}

.quantityNumber {
  font-size: 13px;
  padding: 0px 20px;
}
.discount {
  visibility: hidden;
}
.isMaximumQuantity {
  cursor:not-allowed;
}
.isMaximumQuantity svg path {
  fill: var(--ui-grey-25)
}
