.hitsContainer {
  > a:nth-of-type(n + 7) {
    display: none;
  }
  @screen md {
    > a:nth-of-type(n + 7) {
      display: block;
    }
  }
}
