.root {
  min-height: 100vh;
}

.root.empty {
  @apply bg-secondary text-secondary;
}

.lineItemsList {
  @apply py-4 sm:py-0 sm:space-y-0 divide-y divide-gray-300 border-gray-300;
}

.paymentIcons {
  width: 80%;
  margin: auto;
  display: flex;
  margin-top: 12px;
  justify-content: space-around;
}
