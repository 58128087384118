.textFirst {
  -webkit-animation: textFirstAnimation 10s infinite ease;
  position: absolute;
  width: 100%;
  opacity: 0;
  @apply flex flex-col md:flex-row justify-center text-center md:justify-start md:text-left w-full;
}

.textFirst > div,
.textSecond > div {
  @apply md:mr-2;
}

.textSecond {
  -webkit-animation: textSecondAnimation 10s infinite ease;
  position: absolute;
  opacity: 0;
  @apply flex flex-col md:flex-row justify-center text-center md:justify-start md:text-left w-full;
}

@-webkit-keyframes textFirstAnimation {
  0% {
    opacity: 1;
  }
  47% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  97% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes textSecondAnimation {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
