.root {
  @apply relative text-sm bg-accent-0 text-base w-full transition-colors duration-150 border border-accent-2;
}

.input {
  @apply bg-transparent px-3 py-2 appearance-none w-full transition duration-150 ease-in-out pr-10;
}

.input::placeholder {
  @apply text-accent-3;
}

.input:focus {
  @apply outline-none shadow-outline-normal;
}

.iconContainer {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}

.icon {
  @apply h-5 w-5;
}

.searchForm {
  max-width: 640px;
  margin: auto;
  padding: 0px 20px;
}

@screen sm {
  .input {
    min-width: 300px;
  }
}
