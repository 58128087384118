.header {
  @apply sticky top-0 pl-4 py-4 pr-6
  flex items-center justify-between 
  bg-brand-light-grey box-border w-full z-10;
  min-height: 56px;
}

.container {
  @apply flex flex-col flex-1 box-border;
}

@screen lg {
  .header {
    min-height: 72px;
  }
}

.root {
  @apply absolute z-10 bg-brand-light-grey inset-0 transform transition-transform duration-300 h-full flex flex-col min-h-screen overflow-auto;
  --tw-translate-x: 100%;
}
.loginSidebarOpen {
  --tw-translate-x: 0;
}
