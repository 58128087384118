.root {
  @apply flex flex-col py-4;
}

.root:first-child {
  padding-top: 0;
}

.quantity {
  appearance: textfield;
  @apply w-8 border-accent-2 border mx-3 rounded text-center text-sm text-black;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}

.productImage {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(201, 201, 201, 1) !important;
  border-radius: 4px !important;
  /* position: absolute;
  transform: scale(1.9);
  width: 100%;
  height: 100%;
  left: 30% !important;
  top: 30% !important;
  z-index: 1; */
}

.productName {
  /* @apply font-medium cursor-pointer pb-1;
  margin-top: -4px; */
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(71, 71, 71, 1);
}

.discountPrice {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  @apply text-text-sale;
}

.discountPriceGreen {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  @apply text-promo-green;
}

.discountPriceGold {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #c59b15;
}

.basePrice {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: rgba(147, 147, 147, 1);
  text-decoration: line-through;
  text-align: right;
}
