.root {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  height: 48px;
  width: auto;
  font-family: 'Lato', sans-serif;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 0 16px;
  background: white;
  color: #474747;
  @apply sm:text-14;
}

.root:focus {
  @apply outline-none shadow-outline-normal;
}

.root:hover {
  border: 1px solid rgba(100, 100, 100, 1);
}

.root:disabled {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background: #fafafa;
}

.root::placeholder {
  font-style: normal;
  font-weight: 400;
  color: #939393;
}

.label {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #646464;
  padding-bottom: 8px;
}
