.root {
  background: var(--colour-polar-white);
}

.link {
  & > svg {
    @apply transform duration-75 ease-linear;
  }

  &:hover > svg {
    @apply scale-110;
  }
}
