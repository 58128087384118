.root {
  @apply relative flex items-center;
}

.list {
  @apply flex flex-row items-center justify-items-end h-full;
}

.item {
  @apply ml-6 cursor-pointer relative transition ease-in-out
  duration-100 flex items-center outline-none text-black;
  line-height: 0;
}

.item:hover {
  @apply opacity-75;
}

.item:first-child {
  @apply ml-0;
}

.item:focus,
.item:active {
  @apply outline-none;
}

.chat {
  @apply hidden;
}


@media screen(lg) {
  .chat {
    @apply inline-block;
  }
}

.bagCount {
  @apply border border-accent-1 bg-secondary text-primary absolute rounded-full flex items-center justify-center font-bold text-xs;
  padding-left: 2.5px;
  padding-right: 2.5px;
  min-width: 1.25rem;
  min-height: 1.25rem;
  right: -0.55rem;
  top: -0.5rem;
}

.chatIcon {
  margin-top: 1px;
}

.avatarButton {
  @apply inline-flex justify-center rounded-full;
}

.mobileMenu {
  @apply flex lg:hidden ml-6 text-white;
}

.avatarButton:focus,
.mobileMenu:focus {
  @apply outline-none;
}

.dropdownDesktop {
  @apply hidden -z-10;
}

@media screen(lg) {
  .dropdownDesktop {
    @apply block;
  }
  .dropdownMobile {
    @apply hidden;
  }
}
